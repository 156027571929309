import React, { useEffect, Suspense, lazy, useState } from 'react'
import { BrowserRouter, Routes, Route, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from './app/hooks'
import { needScrollUpOnChange } from './features/ui/UISlice'
import { App as NativeApp, URLOpenListenerEvent } from '@capacitor/app'

import './App.css'

import Login from './pages/Login'
import Privacy from './pages/Privacy'
import SplashScreen from './pages/SplashScreen'
import CookieBanner from './components/CookieBanner'
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update'
import ForceUpdate from './pages/ForceUpdate'
import { useSelector } from 'react-redux'
import { getMinimumVersion, loadVersions } from './features/settings/SettingsSlice'
import { Capacitor } from '@capacitor/core'
const UserZone = lazy(
  () => import('./UserContext').then((module) => ({default: module.UserZone}))
);
const Home = lazy(
  () => import('./UserContext').then((module) => ({default: module.Home}))
);
const Project = lazy(
  () => import('./UserContext').then((module) => ({default: module.Project}))
);
const RecoverAccount = lazy(
  () => import('./UserContext').then((module) => ({default: module.RecoverAccount}))
);
const ProjectFeed = lazy(
  () => import('./UserContext').then((module) => ({default: module.ProjectFeed}))
);
const ProjectReports = lazy(
  () => import('./UserContext').then((module) => ({default: module.ProjectReports}))
);
const ProjectsLoader = lazy(
  () => import('./UserContext').then((module) => ({default: module.ProjectsLoader}))
);
const ProjectMedia = lazy(
  () => import('./UserContext').then((module) => ({default: module.ProjectMedia}))
);
const ProjectInvoices = lazy(
  () => import('./UserContext').then((module) => ({default: module.ProjectInvoices}))
);
const UserPage = lazy(
  () => import('./UserContext').then((module) => ({default: module.UserPage}))
);

const Logger = () => {
  const location = useLocation();

  useEffect(
    () => {
      if(process.env.NODE_ENV !== 'production'){
        console.log('Route change', location.pathname);
      }
    },
    [location.pathname]
  );

  return <Outlet />;
}

const UIMiddleware = () => {
  const needScroll = useAppSelector(needScrollUpOnChange);

  
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(
    () => {
      NativeApp.addListener('backButton', () => navigate(-1))
      NativeApp.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        const slug = event.url.split('.it').pop()
        if (slug) {
          navigate(slug)
        }
      })
    }, [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => {
      if(needScroll)
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 300);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname]
  );

  return <Outlet />;
}

const ForceUpdateMiddleware = () => {
  const [showForceUpdatePage, setShowForceUpdatePage] = useState(false)
  const minimumVersion = useSelector(getMinimumVersion)
  const dispatch = useAppDispatch()
  const isAndroid = Capacitor.getPlatform().toLowerCase() === 'android'

  function isMinorVersion(version1: string, version2: string) {
    if (isAndroid) {
      return parseInt(version1) < parseInt(version2)
    } else {
      const array1 = version1.split(".")
        .map(number => number.padStart(6, "0"))
      const array2 = version2.split(".")
        .map(number => number.padStart(6, "0"))
      const maxLength = Math.max(array1.length, array2.length)
      for(let i = 0; i < maxLength; i++) {
        const current1 = array1[i] ?? ''
        const current2 = array2[i] ?? ''
        if (current1 < current2) return true
      }
      return false
    }
  }

  useEffect(() => {
    dispatch(loadVersions())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    () => {
      (async () => {
        try {          
          const updateInfo = await AppUpdate.getAppUpdateInfo()
          let currentVersion: string
          if (isAndroid) {
            currentVersion = updateInfo.currentVersionCode;
          } else {
            currentVersion = updateInfo.currentVersionName;
          }
          if (
            updateInfo.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE &&
            isMinorVersion(currentVersion, minimumVersion || '')
          ) {
            setShowForceUpdatePage(true)
          }
        } catch {}
      })()
    }, [minimumVersion] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return showForceUpdatePage ? <ForceUpdate /> : <Outlet />;
}

function App(){
  return (
    <BrowserRouter>
      <CookieBanner />
      <Suspense fallback={<SplashScreen />}>
        <Routes>
          <Route path="/" element={<ForceUpdateMiddleware />}>
            <Route path="/" element={<UIMiddleware />}>
              <Route path="/" element={<Logger />}>
                <Route path="/login" element={<Login />} />
                <Route path="/recover" element={<RecoverAccount />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/" element={<UserZone />}>
                  <Route index element={<Home />} />
                  <Route path="/user" element={<UserPage />} />
                  <Route path="/project/:projectid" element={<ProjectsLoader />}>
                    <Route index element={<Project />} />
                    <Route path="feed">
                      <Route index element={<ProjectFeed />} />
                      <Route path=":fileid" element={<ProjectFeed />} />
                      <Route path="chat">
                        <Route index element={<ProjectFeed />} />
                        <Route path=":chatid" element={<ProjectFeed />} />
                      </Route>
                    </Route>
                    <Route path="reports">
                      <Route index element={<ProjectReports />} />
                      <Route path=":fileid" element={<ProjectReports />} />
                      <Route path="chat">
                        <Route index element={<ProjectReports />} />
                        <Route path=":chatid" element={<ProjectReports />} />
                      </Route>
                    </Route>
                    <Route path="invoices">
                      <Route index element={<ProjectInvoices />} />
                      <Route path=":fileid" element={<ProjectInvoices />} />
                      <Route path="chat">
                        <Route index element={<ProjectInvoices />} />
                        <Route path=":chatid" element={<ProjectInvoices />} />
                      </Route>
                    </Route>
                    <Route path="media">
                      <Route index element={<ProjectMedia />} />
                      <Route path=":fileid" element={<ProjectMedia />} />
                      <Route path="chat">
                        <Route index element={<ProjectMedia />} />
                        <Route path=":chatid" element={<ProjectMedia />} />
                      </Route>
                    </Route>
                    <Route path="chat">
                      <Route index element={<Project />} />
                      <Route path=":chatid" element={<Project />} />
                    </Route>
                  </Route>
                  <Route path="chat">
                    <Route index element={<Home />} />
                    <Route path=":chatid" element={<Home />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
